<template>
    <div class="playing-cards" @click="playDeb">
        <div ref="table" class="pc-table">
            <div
                v-for="(card, index) in cards"
                :key="`pc-${index + 1}`"
                :class="`pc-card pc-card--enter-${index + 1} ${card.leave ? 'pc-card--leave-' + (index + 1) : ''} pc-${color}`"
            >
                <span
                    :class="`pc-card-symbol pc-${color}-symbol pc-${color}-symbol${
                        card.red ? '--red' : ''
                    }`"
                >
                    {{ card.value }}
                </span>
            </div>
        </div>
    </div>
</template>

<!-- TODO: script setup -->
<script>
import '@/styles/playing-cards.scss';

export default {
    name: 'CPlayingCards',
    data: () => ({
        symbols: [
            { value: '♣', name: 'clubs' },
            { value: '♠', name: 'spades' },
            { value: '♥', name: 'hearts' },
            { value: '♦', name: 'diamonds' }
        ],
        startingHands: 0,
        cardWidth: 100,
        cardHeight: 150,
        cards: [],
        timeoutId: 0,
        maxCards: 14
    }),
    computed: {
        cardCount() {
            return this.cards.length;
        },
        color() {
            return this.$vuetify.theme.global.name;
        }
    },
    mounted() {
        this.start();
    },
    methods: {
        getTableCenter() {
            const table = this.$refs.table;
            return {
                x:
                    table.offsetLeft +
                    table.offsetWidth / 2 -
                    this.cardWidth / 2,
                y:
                    table.offsetTop +
                    table.offsetHeight / 2 -
                    this.cardHeight / 2
            };
        },
        pushCard() {
            this.cards.push(this.createRandomCard());
        },
        play() {
            if (this.cardCount < this.maxCards && this.cardCount > 7) {
                if (Math.round(Math.random())) this.pushCard();
                else this.changeCardAnimation();
            } else if (this.cardCount == this.maxCards) {
                this.changeCardAnimation();
            } else {
                this.pushCard();
            }
        },
        changeCardAnimation() {
            const index = Math.round(Math.random() * (this.cardCount - 1));
            const card = this.cards[index];
            card.leave = !card.leave;
        },
        createRandomCard() {
            const index = Math.floor(Math.random() * this.symbols.length);
            return {
                ...this.symbols[index],
                red: Math.random() >= 0.5
            };
        },
        start() {
            if (this.startingHands < 7) {
                this.startingHands++;
                this.play();
                setTimeout(this.start, 1000);
            } else {
                setTimeout(this.continue, 8000);
            }
        },
        continue() {
            this.play();
            setTimeout(this.continue, 8000);
        },
        debounce(fn, delay) {
            clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => fn(), delay);
        },
        playDeb(payload) {
            this.debounce(async () => await this.play(payload), 300);
        }
    }
};
</script>
