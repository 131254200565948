let playingIs = 'on';

let timeoutId = 0;

function debounce(fn, delay) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn(), delay);
}

const AUDIO = Object.freeze({
    _element: new Audio(),
    async pause() {
        try {
            AUDIO._element.pause();
            return true;
        } catch {
            return false;
        }
    },
    get playingIs() {
        return playingIs;
    },
    set playingIs(value) {
        playingIs = value || 'off';
    },
    async _play(name) {
        if (playingIs !== 'on') return false;
        try {
            AUDIO._element.src = (await AUDIO[name]).default || '';
            await AUDIO._element.play();
            return true;
        } catch {
            return false;
        }
    },
    _playDeb(name) {
        debounce(async () => await AUDIO._play(name), 200);
    },
    _cardFlipSrc: import('@/assets/audios/card-flip-f4ngy.m4a'),
    _fireworksSrc: import('@/assets/audios/fireworks-mrgear123.m4a'),
    cardFlip: async () => AUDIO._playDeb('_cardFlipSrc'),
    fireworks: async () => AUDIO._playDeb('_fireworksSrc')
});

export const useAudio = () => AUDIO;
