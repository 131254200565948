<template>
    <div v-if="animationIsOn && transitions.includes($props.transition)">
        <v-fab-transition v-if="$props.transition === 'fab'">
            <slot />
        </v-fab-transition>
        <v-fab-transition v-else-if="$props.transition === 'expand'">
            <slot />
        </v-fab-transition>
        <v-fab-transition v-else-if="$props.transition === 'scroll-y-reverse'">
            <slot />
        </v-fab-transition>
        <v-fade-transition v-else>
            <slot />
        </v-fade-transition>
    </div>
    <slot v-else />
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useMainStore } from '@/store';

const { animationIsOn } = storeToRefs(useMainStore());

const transitions = ['fab', 'expand', 'scroll-y-reverse'];

defineProps({
    transition: {
        type: String,
        default: () => 'fab'
    }
});
</script>
