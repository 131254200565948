import { useLs } from '@/services/ls.js';

import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';
import { es, en, pt } from 'vuetify/locale';
import { md3 } from 'vuetify/blueprints';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import colors from 'vuetify/lib/util/colors';

export default (i18n) => {
    const vuetifyLocales = { en, pt, es };
    const messages = {};
    const availableLocales = i18n.global.availableLocales;
    for (let locale of availableLocales) {
        messages[locale] = vuetifyLocales[locale];
    }
    return {
        blueprint: md3,
        theme: {
            defaultTheme: useLs().get('theme', 'dark'),
            options: { customProperties: true },
            variations: {
                colors: ['primary', 'secondary'],
                lighten: 2,
                darken: 1
            },
            themes: {
                light: {
                    dark: false,
                    colors: {
                        // primary: colors.lightGreen.lighten3,
                        primary: colors.lightGreen.darken1,
                        secondary: colors.lightGreen.darken4,
                        // 'on-secondary': colors.shades.white,
                        background: colors.grey.lighten4,
                        surface: colors.shades.white,
                        'on-surface': '#000500',
                        text: '#121212'
                    }
                },
                dark: {
                    dark: true,
                    colors: {
                        primary: colors.lightGreen.darken4,
                        secondary: colors.lightGreen.darken1,
                        // 'on-secondary': colors.shades.black,
                        background: '#121212',
                        surface: colors.shades.black,
                        'on-surface': '#eeffee',
                        text: colors.grey.lighten4
                    }
                }
            }
        },
        icons: {
            defaultSet: 'mdi',
            aliases,
            sets: {
                mdi
            }
        },
        locale: {
            messages,
            current: i18n.global.locale || 'en',
            name: i18n.global.locale || 'en',
            fallback: 'en'
        }
    };
};
