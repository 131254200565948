if (
    import.meta.env.VITE_BASE_URL === 'https://dev.conectaplanning.com.br' &&
    window?.location?.host &&
    window.location.host !== 'dev.conectaplanning.com.br'
)
    window.location.replace('https://dev.conectaplanning.com.br');

import { useLs, lsPlugin } from '@/services/ls.js';

import { createApp } from 'vue';

import { createI18n } from 'vue-i18n';
import vueI18nMessages from '@/locales';

import { createVuetify } from 'vuetify';
import vuetifyConfig from '@/plugins/vuetify.js';

import { createPinia } from 'pinia';
import { useAuthStore } from '@/store';

import { createRouter, createWebHistory } from 'vue-router';
import { routes, beforeEach } from '@/router';

import { insertFavicon } from '@/services/utils.js';
import { initialize } from '@/services/firebase.js';
import { useFbAnalytics } from '@/services/firebase-analytics.js';

insertFavicon();

const dev = import.meta.env.MODE !== 'production';

const defaultLocale = 'en';
const locale = useLs().get('locale', 'en');

const i18n = createI18n({
    legacy: false,
    locale,
    fallbackLocale: defaultLocale,
    messages: vueI18nMessages,
    fallbackWarn: dev,
    missingWarn: dev
});

const vuetify = createVuetify(vuetifyConfig(i18n));

const pinia = createPinia();
initialize();
useFbAnalytics().setUserId();

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { top: 0, behavior: 'smooth' };
    }
});
router.beforeEach(beforeEach(useAuthStore));
// router.onError((e) => console.log(e));

import App from '@/w-app.vue';

export default createApp(App)
    .use(lsPlugin)
    .use(i18n)
    .use(vuetify)
    .use(pinia)
    .use(router)
    .mount('#app');
