<template>
    <v-select
        class="mx-3"
        color="secondary"
        density="compact"
        :variant="props.variant"
        single-line
        hide-details
        :prepend-icon="themeIconKey"
        style="max-width: fit-content"
        :label="$t('common.theme')"
        :model-value="theme"
        :items="Object.values(themes)"
        @update:model-value="changeTheme"
    />
</template>

<script setup>
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { useTheme } from 'vuetify';
import { useMainStore } from '@/store';
import { useLs } from '@/services/ls.js';

const props = defineProps({
    variant: {
        type: String,
        default: () => 'outlined'
    }
});

const ls = useLs();

const vuetifyTheme = useTheme();

const { t } = useI18n();

const { theme } = storeToRefs(useMainStore());

const themes = computed(() => ({
    light: { title: t('common.light'), value: 'light' },
    dark: { title: t('common.dark'), value: 'dark' }
}));

const lightThemeIconKey = 'mdi-weather-sunny';
const darkThemeIconKey = 'mdi-weather-night';

const themeIconKey = computed(() =>
    theme.value == 'light' ? lightThemeIconKey : darkThemeIconKey
);

function changeTheme(value) {
    theme.value = themes.value[value];
    vuetifyTheme.name = value;
    ls.set('theme', value);
}
</script>
