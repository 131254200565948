import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getDatabase, connectDatabaseEmulator } from 'firebase/database';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';

const config = {
    apiKey: 'AIzaSyAnzpLCg1B0qqUfAZIlDZqPK2AMo2hC7M8',
    authDomain: 'poker-da-conec.firebaseapp.com',
    databaseURL: 'https://poker-da-conec-default-rtdb.firebaseio.com',
    projectId: 'poker-da-conec',
    storageBucket: 'poker-da-conec.appspot.com',
    messagingSenderId: '235472099204',
    appId: '1:235472099204:web:aa9893ffeb19958da5efd1',
    measurementId: 'G-C0P12R131X'
};

export function initialize() {
    const authDomain = import.meta.env.VITE_AUTH_DOMAIN;
    const app = initializeApp({ ...config, authDomain });
    if (
        location?.hostname === 'localhost' &&
        import.meta.env.MODE === 'testing'
    ) {
        const firestoreURL = import.meta.env.VITE_FIRESTORE_URL;
        const databaseURL = import.meta.env.VITE_DATABASE_URL;
        connectAuthEmulator(getAuth(), authDomain);
        connectDatabaseEmulator(getDatabase(), ...databaseURL.split(':'));
        connectFirestoreEmulator(getFirestore(), ...firestoreURL.split(':'));
    }
    return app;
}
