<template>
    <v-locale-provider>
        <v-theme-provider :theme="theme?.value">
            <v-app>
                <v-app-bar
                    v-if="$route.meta.showAppBar"
                    app
                    density="compact"
                    color="background"
                    class="py-1"
                >
                    <v-app-bar-nav-icon @click="goHome">
                        <template #default>
                            <v-img
                                min-width="36"
                                max-width="36"
                                :src="
                                    theme?.value === 'dark'
                                        ? logoWhiteSrc
                                        : logoSrc
                                "
                            />
                        </template>
                    </v-app-bar-nav-icon>
                    <v-btn @click="goHome">
                        <v-img
                            min-width="288"
                            max-width="288"
                            :src="
                                theme?.value == 'dark'
                                    ? logoTextWhiteSrc
                                    : logoTextSrc
                            "
                            style="cursor: pointer"
                        />
                    </v-btn>
                    <v-spacer />
                    <v-menu
                        v-if="user && !loadingAuth"
                        :close-on-content-click="false"
                        :offset="6"
                    >
                        <template #activator="{ props }">
                            <v-btn
                                rounded="circle"
                                v-bind="props"
                                min-width="38"
                                class="me-5 px-0"
                            >
                                <v-avatar size="36">
                                    <img
                                        v-if="user?.photo"
                                        :src="user?.photo"
                                        :alt="user?.name"
                                        :width="36"
                                        referrerpolicy="no-referrer"
                                    />
                                    <v-icon
                                        v-else
                                        icon="mdi-account-circle"
                                        dark
                                        class="me-1"
                                    />
                                </v-avatar>
                            </v-btn>
                        </template>
                        <v-list lines="two">
                            <v-list-item>
                                <template #default>
                                    <c-select-theme variant="underlined" />
                                </template>
                            </v-list-item>
                            <v-list-item>
                                <template #default>
                                    <c-select-locale variant="underlined" />
                                </template>
                            </v-list-item>
                            <v-list-item>
                                <template #default>
                                    <v-btn
                                        variant="text"
                                        color="on-surface"
                                        class="mx-3 pa-0"
                                        height="40"
                                        :disabled="player?.status === 'online'"
                                        @click="toggleAnimation"
                                    >
                                        <v-icon
                                            :icon="
                                                animationIsOn
                                                    ? 'mdi-eye'
                                                    : 'mdi-eye-off'
                                            "
                                            size="24"
                                            class="me-4"
                                        />
                                        {{
                                            $t(
                                                `button.animation.${animationStatus}`
                                            )
                                        }}
                                    </v-btn>
                                </template>
                            </v-list-item>
                            <v-list-item>
                                <template #default>
                                    <v-btn
                                        variant="text"
                                        color="on-surface"
                                        class="mx-3 pa-0"
                                        height="40"
                                        @click="toggleSound"
                                    >
                                        <v-icon
                                            :icon="
                                                soundIsOn
                                                    ? 'mdi-volume-high'
                                                    : 'mdi-volume-off'
                                            "
                                            size="24"
                                            class="me-4"
                                        />
                                        {{ $t(`button.sound.${soundStatus}`) }}
                                    </v-btn>
                                </template>
                            </v-list-item>
                            <v-list-item>
                                <template #default>
                                    <v-btn
                                        v-if="user && !loadingAuth"
                                        variant="text"
                                        color="on-surface"
                                        class="mx-3 pa-0"
                                        height="40"
                                        @click="leave"
                                    >
                                        <v-icon
                                            icon="mdi-logout"
                                            size="24"
                                            class="me-4"
                                        />
                                        {{ $t('button.signOut') }}
                                    </v-btn>
                                </template>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-app-bar>
                <v-main app>
                    <c-playing-cards
                        v-if="$route.meta.showPlayingCards && animationIsOn"
                        ref="playingCards"
                    />
                    <v-overlay
                        v-if="
                            $route.meta.showOverlay &&
                            $route.meta.showPlayingCards &&
                            $refs?.playingCards?.$el
                        "
                        :model-value="true"
                        class="align-center justify-center"
                        contained
                        persistent
                        :attach="$refs?.playingCards?.$el"
                        :opacity="0.07"
                    />
                    <router-view v-slot="{ Component }">
                        <v-container
                            :class="`fill-height ${$route.meta.class || ''}`"
                            @click="$refs.playingCards?.playDeb()"
                        >
                            <v-row
                                justify="center"
                                align="center"
                                no-gutters
                                :class="
                                    $route.meta.fillHeight ? 'fill-height' : ''
                                "
                            >
                                <v-col
                                    align="center"
                                    :class="
                                        $route.meta.fillHeight
                                            ? 'fill-height'
                                            : ''
                                    "
                                >
                                    <c-transition>
                                        <component :is="Component" />
                                    </c-transition>
                                </v-col>
                            </v-row>
                        </v-container>
                    </router-view>
                </v-main>
            </v-app>
        </v-theme-provider>
    </v-locale-provider>
</template>

<script>
import { storeToRefs } from 'pinia';
import { useLocale, useTheme } from 'vuetify';
import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router';
import { useAudio } from '@/services/audio.js';
import { useLs } from '@/services/ls.js';
import { useMainStore, useAuthStore, useGameStore } from '@/store';

import cPlayingCards from '@/components/c-playing-cards.vue';
import cSelectTheme from '@/components/c-select-theme.vue';
import cSelectLocale from '@/components/c-select-locale.vue';
import cTransition from '@/components/c-transition.vue';

import logoWhiteSrc from '@/assets/logo-white.png';
import logoTextWhiteSrc from '@/assets/logo-text-white.png';

import logoSrc from '@/assets/logo.png';
import logoTextSrc from '@/assets/logo-text.png';

export default {
    components: {
        cPlayingCards,
        cSelectTheme,
        cSelectLocale,
        cTransition
    },
    setup() {
        const audio = useAudio();
        const ls = useLs();

        const authStore = useAuthStore();
        const gameStore = useGameStore();

        const router = useRouter();
        const route = useRoute();

        const {
            theme,
            locale,
            animationStatus,
            soundStatus,
            animationIsOn,
            soundIsOn
        } = storeToRefs(useMainStore());
        const { user, loadingAuth } = storeToRefs(authStore);

        const { player } = storeToRefs(gameStore);
        const { leaveRoom } = gameStore;
        const { signOut } = authStore;

        async function goHome() {
            if (route.name != 'Home')
                return await router.push({ name: 'Home' });
        }
        async function leave() {
            await leaveRoom();
            await signOut();
            return await router.push({ name: 'Login' });
        }
        function toggleAnimation() {
            animationStatus.value = animationIsOn.value ? 'off' : 'on';
            ls.set('animationStatus', animationStatus.value);
        }
        function toggleSound() {
            soundStatus.value = soundIsOn.value ? 'off' : 'on';
            audio.playingIs = soundStatus.value;
            if (!soundIsOn.value) audio.pause();
            ls.set('soundStatus', soundStatus.value);
        }

        return {
            logoWhiteSrc,
            logoTextWhiteSrc,
            logoSrc,
            logoTextSrc,
            user,
            loadingAuth,
            player,
            goHome,
            leave,
            toggleAnimation,
            toggleSound,
            theme,
            locale,
            animationStatus,
            soundStatus,
            animationIsOn,
            soundIsOn
        };
    },
    mounted() {
        const vuetifyLocale = useLocale();
        const vuetifyTheme = useTheme();
        const audio = useAudio();

        const { locale: i18nLocale } = useI18n();

        const { theme, locale, soundStatus } = storeToRefs(useMainStore());

        if (theme.value) vuetifyTheme.global.name = theme.value;
        if (locale.value) {
            vuetifyLocale.current = locale.value;
            i18nLocale.value = locale.value;
        }
        if (soundStatus.value) audio.playingIs = soundStatus.value;
    }
};
</script>

<style>
html {
    overflow-y: auto !important;
}
.elevation-1.theme--dark {
    box-shadow:
        0 2px 1px -1px rgba(100, 100, 100, 0.2),
        0 1px 1px 0 rgba(100, 100, 100, 0.14),
        0 1px 3px 0 rgba(100, 100, 100, 0.12) !important;
}
.elevation-2.theme--dark,
.v-btn--is-elevated.theme--dark {
    box-shadow:
        0 3px 1px -2px rgba(100, 100, 100, 0.2),
        0 2px 2px 0 rgba(100, 100, 100, 0.14),
        0 1px 5px 0 rgba(100, 100, 100, 0.12) !important;
}
</style>
