// TODO: rename to game.js

import { defineStore } from 'pinia';
import { useLs } from '@/services/ls.js';
import { useAuthStore } from '@/store/auth.js';
import { useCardStore } from '@/store/card.js';
import { useGameStore } from '@/store/game.js';

const ls = useLs();

const state = () => ({
    theme: ls.get('theme', 'dark'),
    locale: ls.get('locale', 'en'),
    showAppBar: true,
    animationStatus: ls.get('animationStatus', 'on'),
    soundStatus: ls.get('soundStatus', 'on')
});

const getters = {
    animationIsOn: (state) => state.animationStatus === 'on',
    soundIsOn: (state) => state.soundStatus === 'on'
};

const actions = {};

const useMainStore = defineStore('main', {
    state,
    getters,
    actions
});

export { useMainStore, useAuthStore, useCardStore, useGameStore };
